import React from "react";
import BlogArticleCard from "./blog-article-card";
import { Link } from "gatsby";
const BlogArticleGrid = ({ articles }) => {
  return (
    <>
      <section class="ud-relative ud-z-10 ud-overflow-hidden ud-pt-[150px]">
        <div class="ud-container">
          <div class="ud-mx-[-16px] ud-flex ud-flex-wrap ud-items-center">
            <div class="ud-w-full ud-px-4 md:ud-w-8/12 lg:ud-w-7/12">
              <div class="ud-mb-12 ud-max-w-[570px] md:ud-mb-0">
                <h1
                  class="
                ud-mb-5 ud-text-2xl
                ud-font-bold
                ud-text-black
                dark:ud-text-white
                sm:ud-text-3xl
              "
                >
                  Blogg
                </h1>
                <p
                  class="
                ud-text-base
                ud-font-medium
                ud-leading-relaxed
                ud-text-body-color
              "
                >
                  Zwops blogg om B2B försäljning, leads och affärsinformation
                </p>
              </div>
            </div>
            <div class="ud-w-full ud-px-4 md:ud-w-4/12 lg:ud-w-5/12">
              <div class="ud-text-end">
                <ul class="ud-flex ud-items-center md:ud-justify-end">
                  <li class="ud-flex ud-items-center">
                    <Link
                      className="
                  ud-pr-1 ud-text-base ud-font-medium ud-text-body-color
                    hover:ud-text-primary
                    "
                      to={"/"}
                    >
                      Hem
                    </Link>

                    <span
                      class="
                    ud-mr-3
                    ud-block
                    ud-h-2
                    ud-w-2
                    ud-rotate-45
                    ud-border-t-2
                    ud-border-r-2
                    ud-border-body-color
                  "
                    ></span>
                  </li>
                  <li class="ud-text-base ud-font-medium ud-text-primary">
                    Blogg
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div>
          <span class="ud-absolute ud-top-0 ud-left-0 ud-z-[-1]">
            <svg
              width="287"
              height="254"
              viewBox="0 0 287 254"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.1"
                d="M286.5 0.5L-14.5 254.5V69.5L286.5 0.5Z"
                fill="url(#paint0_linear_111:578)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_111:578"
                  x1="-40.5"
                  y1="117"
                  x2="301.926"
                  y2="-97.1485"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#4A6CF7" />
                  <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </span>
          <span class="ud-absolute ud-right-0 ud-top-0 ud-z-[-1]">
            <svg
              width="628"
              height="258"
              viewBox="0 0 628 258"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.1"
                d="M669.125 257.002L345.875 31.9983L524.571 -15.8832L669.125 257.002Z"
                fill="url(#paint0_linear_0:1)"
              />
              <path
                opacity="0.1"
                d="M0.0716344 182.78L101.988 -15.0769L142.154 81.4093L0.0716344 182.78Z"
                fill="url(#paint1_linear_0:1)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_0:1"
                  x1="644"
                  y1="221"
                  x2="429.946"
                  y2="37.0429"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#4A6CF7" />
                  <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_0:1"
                  x1="18.3648"
                  y1="166.016"
                  x2="105.377"
                  y2="32.3398"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#4A6CF7" />
                  <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </span>
        </div>
      </section>
      <section class="ud-pt-[120px] ud-pb-[120px]">
        <div class="ud-container">
          <div class="ud-mx-[-16px] ud-flex ud-flex-wrap ud-justify-center">
            {articles.map((article) => (
              <BlogArticleCard article={article} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogArticleGrid;
