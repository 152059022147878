import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import BlogArticeGrid from "../components/blog-article-grid";
import Seo from "../components/seo";

const BlogPage = () => {
  const { allStrapiArticle, strapiGlobal } = useStaticQuery(graphql`
    query {
      allStrapiArticle {
        nodes {
          ...BlogArticleCard
        }
      }
      strapiGlobal {
        siteName
        siteDescription
      }
    }
  `);

  return (
    <Layout>
      <Seo seo={{ metaTitle: "Blogg" }} />

      <main>
        <BlogArticeGrid articles={allStrapiArticle.nodes} />
      </main>
    </Layout>
  );
};

export default BlogPage;
