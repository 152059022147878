import React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BlogArticleCard = ({ article }) => {
  return (
    <div class="ud-w-full ud-px-4 md:ud-w-2/3 lg:ud-w-1/2 xl:ud-w-1/3">
      <Link
        to={`/blog/${article.slug}`}
        className="overflow-hidden rounded-lg bg-white shadow-sm transition-shadow hover:shadow-md"
      >
        <div
          class="
    wow fadeInUp
    ud-relative
    ud-mb-10 ud-overflow-hidden ud-rounded-md ud-bg-white
    ud-shadow-one
    dark:ud-bg-dark
  "
          data-wow-delay=".1s"
        >
          <span
            class="
        ud-absolute
        ud-top-6
        ud-right-6
        ud-inline-flex
        ud-items-center
        ud-justify-center
        ud-rounded-full
        ud-bg-primary
        ud-py-2
        ud-px-4
        ud-text-sm
        ud-font-semibold
        ud-text-white
      "
          >
            {article.category.name}
          </span>
          <GatsbyImage
            image={getImage(article.cover?.localFile)}
            alt={article.cover?.alternativeText}
            class="ud-w-full"
          />

          <div
            class="
            ud-p-6
      ud-text-body-color
      sm:ud-p-8
      md:ud-py-8 md:ud-px-6
      lg:ud-p-8
      xl:ud-py-8 xl:ud-px-5
      2xl:ud-p-8
    "
          >
            <h1
              class="
              ud-mb-2 ud-text-2xl
              ud-font-bold
              ud-text-black
              dark:ud-text-white
              sm:ud-text-3xl
              lg:ud-text-3xl
              xl:ud-text-3xl
           "
            >
              {article.title}
            </h1>
            <h2
              class="
        ud-mb-6
        ud-border-b
        ud-border-body-color
        ud-border-opacity-10
        ud-pb-6
        ud-text-base
        ud-font-medium
        ud-text-body-color
        dark:ud-border-white dark:ud-border-opacity-10
      "
            >
              {article.description}
            </h2>
            <div class="ud-flex ud-items-center">
              <div
                class="
          ud-mr-5 ud-flex ud-items-center ud-border-r
          ud-border-body-color
          ud-border-opacity-10
          ud-pr-5
          dark:ud-border-white
          dark:ud-border-opacity-10 xl:ud-mr-3 xl:ud-pr-3
          2xl:ud-mr-5 2xl:ud-pr-5
        "
              >
                <div
                  class="
            ud-mr-4
            ud-h-[40px]
            ud-w-full
            ud-max-w-[40px]
            ud-overflow-hidden
            ud-rounded-full
          "
                >
                  <GatsbyImage
                    image={getImage(article.author.avatar?.localFile)}
                    alt={article.author.avatar?.alternativeText}
                    class="ud-w-full"
                  />
                </div>
                <div class="ud-w-full">
                  <h4
                    class="
              ud-mb-1 ud-text-sm ud-font-medium
              ud-text-dark
              dark:ud-text-white
            "
                  >
                    {article.author.name}
                  </h4>
                  <p class="ud-text-xs ud-text-body-color">
                    {article.author.role}
                  </p>
                </div>
              </div>
              <div class="ud-inline-block">
                <h4
                  class="
            ud-mb-1 ud-text-sm ud-font-medium
            ud-text-dark
            dark:ud-text-white
          "
                ></h4>
                <p class="ud-text-xs ud-text-body-color">
                  {article.publishedAt}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export const query = graphql`
  fragment BlogArticleCard on STRAPI_ARTICLE {
    id
    slug
    title
    description
    publishedAt(formatString: "YYYY-MM-DD", locale: "sv-SE")
    author {
      name
      role
      avatar {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(aspectRatio: 1)
          }
        }
      }
    }
    category {
      name
    }
    cover {
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1.77)
        }
      }
    }
  }
`;

// console.log(query);

export default BlogArticleCard;
